import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const wizardTrackingIds = {
  openIncentivesMatcher: 'open_incentives_matcher',
  closeDrawer: 'close_drawer',
  displayIneligibleIncentiveDetails: 'display_ineligible_incentive_details',
  incentivesOffer: 'incentive_offer',
};

const map = {
  [wizardTrackingIds.openIncentivesMatcher]: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_FILL_FORM,
    TrackingConstant.OPEN_INCENTIVES_MATCHER
  ),
  [wizardTrackingIds.closeDrawer]: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_FILL_FORM,
    TrackingConstant.CLOSE_DRAWER
  ),
  [wizardTrackingIds.displayIneligibleIncentiveDetails]: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.DISPLAY_INELIGIBLE_INCENTIVES_DETAILS
  ),
  [wizardTrackingIds.incentivesOffer]: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.INCENTIVE_OFFER
  ),
};

export const IncentivesWizardMap = {
  ...map,
};
