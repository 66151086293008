import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getTreehouseEligiblePath, TreehouseModel } from 'client/data/models/treehouse';

function TreehousePromotionCheckUI({ isTreehouseEligible, children, fallback }) {
  const [showPromo, setShowPromo] = useState(isTreehouseEligible);

  useEffect(() => {
    // checks for eligibility until eligible for current zip
    if (!showPromo) {
      setShowPromo(isTreehouseEligible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTreehouseEligible]);

  if (showPromo === null) {
    return null;
  }

  return showPromo ? children : fallback;
}

TreehousePromotionCheckUI.propTypes = {
  isTreehouseEligible: PropTypes.bool,
  fallback: PropTypes.node,
  children: PropTypes.node,
};

TreehousePromotionCheckUI.defaultProps = {
  isTreehouseEligible: null,
  fallback: null,
  children: null,
};

export const TreehousePromotionCheck = connectToModel(TreehousePromotionCheckUI, {
  isTreehouseEligible: bindToPath(getTreehouseEligiblePath, TreehouseModel),
});
