import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import convexMask from 'site-modules/shared/components/clipped-container/mask-library/convex-header';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';

import './convex-header.scss';

export function ConvexHeader({ innerMask, className }) {
  const id = useId();

  return (
    <ClippedContainer
      containerClasses={classnames('convex-header pos-a top-0 left-0 w-100', className)}
      mask={innerMask || { ...convexMask, name: id }}
    />
  );
}
ConvexHeader.propTypes = {
  innerMask: PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }),
  className: PropTypes.string,
};
ConvexHeader.defaultProps = { innerMask: null, className: null };
