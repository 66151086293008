import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getTreehouseEligiblePath, TreehouseModel } from 'client/data/models/treehouse';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';

import './ready-for-ev.scss';

const TOOLTIP_TRACKING_DATA = {
  true: {
    home: { subaction_name: 'treehouse_tooltip0' },
    time: { subaction_name: 'treehouse_tooltip1' },
    charge: { subaction_name: 'treehouse_tooltip2' },
    partner: { subaction_name: 'treehouse_tooltip3' },
  },
  false: {
    home: { subaction_name: 'view_tooltip_1' },
    time: { subaction_name: 'view_tooltip_2' },
    charge: { subaction_name: 'view_tooltip_3' },
    partner: { subaction_name: 'view_tooltip_4' },
  },
};

export function ReadyForEv({
  className,
  heading,
  headingTag: HeadingTag,
  headingClassName,
  creativeId,
  visitorId,
  pageName,
  isTreehouseEligible,
  hideCta,
  isMobileLayout,
  isTreehousePromo,
  headingStyles,
  wrapperStyles,
}) {
  const onTooltipOpen = useCallback(
    ({ id }) => {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_CONTENT,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
          creative_id: creativeId,
          ...TOOLTIP_TRACKING_DATA[isTreehousePromo][id],
        },
      });
    },
    [creativeId, isTreehousePromo]
  );

  const treehouseLink = `https://treehouse.pro/edmunds?utm_campaign=edmunds_clickout_01&utm_content=get_free_quote&utm_source=edmunds_${pageName}&utm_targetid=${visitorId}`;

  const ITEMS = {
    true: [
      {
        id: 'home',
        icon: 'icon-240-outlet',
        text: 'EV ownership works best if you can charge at home (240V outlet)',
        tooltip:
          "This typically means a 240V home installation, or other places your car is parked for several hours each day. Don't expect a regular household outlet (120V) to suffice.",
      },
      {
        id: 'time',
        icon: 'icon-hour-glass',
        text: (
          <Fragment>
            Adding a home charging system is estimated to cost $1,616 in
            <ZipWithDefaultActionHandling
              buttonClassName="size-16 text-blue-40 fw-normal text-underline"
              showDropdownIcon={false}
            />
          </Fragment>
        ),
        tooltip:
          'This is an estimate for your area. Using your address and the answers you provide, Treehouse can provide a more accurate price.',
      },
      {
        isLastItem: true,
        id: 'charge',
        icon: 'icon-cash3',
        text: (
          <Fragment>
            Edmunds is partnering with Treehouse, an independent provider of home EV installation services.{' '}
            <Link
              to={treehouseLink}
              target="_blank"
              className="text-underline text-blue-40"
              data-tracking-id="treehouse_clickout"
            >
              Learn more
            </Link>
          </Fragment>
        ),
        tooltip:
          'Edmunds customers receive a 10% installation discount and 4% smart charger discount. Discount excludes permit, hosted inspection, and load management devices. Valid for 30 days.',
      },
    ],
    false: [
      {
        id: 'home',
        icon: 'icon-240-outlet',
        text: 'EV ownership works best if you can charge (240V) at home or at work',
        tooltip:
          "This typically means a 240V home installation, but you could also have a similar setup at your office or other places your car is already parked for several hours each day. Don't expect a regular household outlet (120V) to suffice unless you've got a plug-in hybrid, in which case overnight charging at home is feasible.",
      },
      {
        id: 'time',
        icon: 'icon-hour-glass',
        text:
          'If you can’t charge at home, charging at a charging station could take at least 10x longer than at a gas station',
        tooltip:
          "With public charging infrastructure still in its infancy, the user experience can be maddeningly inconsistent. Tesla owners tend to rave about the reliability and speed of the company's proprietary Supercharger stations, but rival DC fast options have thus far been plagued by technical issues and overcrowding. It's an evolving landscape and our best advice is to do your research on the available options for the EV you want to buy.",
      },
      {
        isLastItem: true,
        id: 'charge',
        icon: 'icon-cash3',
        text: 'Adding a 240V home charging system could cost up to $1,600 or more',
        tooltip:
          'If your existing electrical service can handle the additional demands of EV charging, you may be able to add Level 2 charging at home for less than a grand, including installation. But your costs will multiply if you need to upgrade your electrical panel or add a dedicated circuit.',
      },
    ],
  };

  return (
    <div className={classnames('ready-for-ev', className)} data-tracking-parent={creativeId} style={wrapperStyles}>
      {!!heading && (
        <HeadingTag className={headingClassName} style={headingStyles}>
          {heading}
        </HeadingTag>
      )}
      <ul className="list-unstyled">
        {ITEMS[isTreehousePromo].map(({ id, icon, text, tooltip, isLastItem }) => (
          <li
            key={id}
            className={classnames({
              'mb-1': !isLastItem,
              'mb-md-0_75': !isLastItem && !isMobileLayout,
              'd-md-flex align-items-center': !isMobileLayout,
            })}
          >
            <i
              className={classnames(
                'item-icon d-flex align-items-center justify-content-center text-cool-gray-50 bg-blue-100 size-24 mb-0_5 mx-auto',
                icon,
                {
                  'mb-md-0 ml-md-0 mr-md-1': !isMobileLayout,
                }
              )}
              style={{ height: '52px', width: '52px', flexShrink: 0 }}
              aria-hidden
            />
            <div>
              {text}{' '}
              <TooltipItem
                className="top-0 small text-blue-40"
                item={{
                  placement: 'bottom',
                  text: tooltip,
                }}
                id={id}
                onTooltipOpen={onTooltipOpen}
              />
            </div>
          </li>
        ))}
      </ul>
      {!hideCta && (
        <Fragment>
          {isTreehousePromo ? (
            <Fragment>
              <div
                className={classnames('fw-bold mb-1 text-center mt-1_5', {
                  'mt-md-1': !isMobileLayout,
                })}
              >
                Need to install a charger at home?
              </div>
              {isTreehouseEligible ? (
                <div className="text-center">
                  <Button
                    tag={Link}
                    to={treehouseLink}
                    target="_blank"
                    color="blue-50"
                    className="rounded-8 text-white text-transform-none fw-medium size-16 py-0_5 px-1"
                    rel="nofollow"
                    data-tracking-id="treehouse_clickout"
                  >
                    Get a free quote <span className="ml-0_25 icon-new-tab small" aria-hidden />
                  </Button>
                </div>
              ) : (
                <div className="text-danger medium">
                  Sorry! Our partners aren’t offering home charging installations in your area yet.
                </div>
              )}
            </Fragment>
          ) : (
            <div
              className={classnames('text-center mt-1_5', {
                'mt-md-1': !isMobileLayout,
              })}
            >
              <Link
                to="/electric-car/articles/how-long-does-take-charge-electric-car.html"
                className="text-blue-50"
                data-tracking-id="ev_charging_article"
              >
                Learn more about EV charging
              </Link>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

ReadyForEv.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  headingTag: PropTypes.string,
  headingClassName: PropTypes.string,
  creativeId: PropTypes.string,
  visitorId: PropTypes.string,
  pageName: PropTypes.string,
  isTreehouseEligible: PropTypes.bool,
  hideCta: PropTypes.bool,
  isMobileLayout: PropTypes.bool,
  isTreehousePromo: PropTypes.bool,
  headingStyles: PropTypes.shape({}),
  wrapperStyles: PropTypes.shape({}),
};

ReadyForEv.defaultProps = {
  className: null,
  heading: null,
  headingTag: 'h3',
  headingClassName: 'heading-4 mb-1_5',
  creativeId: 'ready-for-an-ev',
  visitorId: null,
  pageName: null,
  isTreehouseEligible: null,
  hideCta: false,
  isMobileLayout: false,
  isTreehousePromo: false,
  headingStyles: null,
  wrapperStyles: null,
};

const mapStateToProps = state => ({
  visitorId: get(state, 'visitor.id'),
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const propsAreEqual = (prevProps, { isTreehouseEligible }) => isTreehouseEligible === null;
export const ReadyForEvTreehouse = connect(mapStateToProps)(
  connectToModel(React.memo(ReadyForEv, propsAreEqual), {
    isTreehouseEligible: bindToPath(getTreehouseEligiblePath, TreehouseModel),
  })
);

ReadyForEvTreehouse.defaultProps = {
  ...omit(ReadyForEv.propTypes, ['isMobile', 'visitorId', 'pageName', 'isTreehouseEligible']),
  isTreehousePromo: PropTypes.bool,
};

ReadyForEvTreehouse.defaultProps = {
  ...omit(ReadyForEv.defaultProps, ['isMobile', 'visitorId', 'pageName', 'isTreehouseEligible']),
  isTreehousePromo: true,
};
