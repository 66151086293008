import { get } from 'lodash';

export function getRebateAmount(incentive) {
  const amount = incentive?.rebateAmount || 0;
  const rebateOverridesByEligibility = get(incentive, 'rebateOverridesByEligibility', []);
  const amountFromEligibilityFactor = rebateOverridesByEligibility[0]?.rebateAmount || 0;
  return amountFromEligibilityFactor || amount;
}

export function getTotalRebateAmount(incentives) {
  return incentives.reduce((sum, incentive) => sum + getRebateAmount(incentive), 0);
}
