import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalEvRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-ev-rebate';
import { isTaxCreditRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-tax-credit-rebate';
import { isChargerInstallationRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-charger-installation-rebate';
import { getTotalRebateAmount } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-rebate-amount-from-incentives';

export function getPotentialRebatesSum(incentives) {
  const filteredIncentives = incentives.filter(incentive =>
    [isOEMRebate, isFederalRebate, isLocalEvRebate, isTaxCreditRebate, isChargerInstallationRebate].some(
      cb => !!cb(incentive)
    )
  );

  return getTotalRebateAmount(filteredIncentives);
}
