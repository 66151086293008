import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './loading-spinner.scss';

export function LoadingSpinner({ children, isLoading, classes }) {
  return (
    <div className={classNames('loading-container', classes)}>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner-container">
            <i className="icon-spinner8 loading-spinner-icon" aria-hidden />
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

LoadingSpinner.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  classes: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  children: null,
  isLoading: false,
  classes: '',
};
