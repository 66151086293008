import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';

export function getCreativeId({ inventoryType = PUB_STATES_LOWERCASE.NEW_USED, incentives }) {
  const federal = incentives.some(isFederalRebate) ? '-federal' : '';
  const local = incentives.some(isLocalRebate) ? '-local' : '';
  const oem = incentives.some(isOEMRebate) ? '-oem' : '';

  return `${inventoryType}-incentives_matcher${federal}${local}${oem}`;
}
